import axios from "axios";
import store from "@/store";
import Vue from "vue";
import { tokenRefresh } from "./auth.js";
import web from "@/plugins/uni-app/uni.webview.1.5.2.js";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["Pragma"] = "no-cache";

const baseURL = process.env.VUE_APP_BASE_API;

const requestAxios = axios.create({
  baseURL: baseURL, // url = base url + request url
  timeout: 60000, // 请求延时
});

// 拦截请求
const useToken = (config) => {
  // 不需要认证的接口不用添加token
  if (config.api && config.api.auth === false) {
    return config;
  }
  const token = store.getters.token;
  if (token) {
    config.headers.Authorization = "Bearer " + token;
  }
  return config;
};
requestAxios.interceptors.request.use(useToken, (err) => {
  return Promise.reject(err);
});

let tokenRefreshing = false;
let timeoutToast = true;

// 拦截响应
requestAxios.interceptors.response.use(
  (response) => {
    const res = response.data;
    res.success = response.status === 200;
    return res;
  },
  async (error) => {
    const res = {
      success: false,
      code: 0,
      msg: error?.response?.data?.message,
    };
    if (error.response?.status === 401) {
      debugger;
      const refreshToken = store.getters.refreshToken;
      if (refreshToken) {
        if (tokenRefreshing) {
          setTimeout(() => {
            requestAxios.request(error.config);
          }, 100);
        } else {
          tokenRefreshing = true;
          const resRefresh = await tokenRefresh({
            refreshToken: refreshToken,
          });
          tokenRefreshing = false;
          if (resRefresh.success) {
            store.dispatch("user/setToken", {
              token: resRefresh.access_token,
              refreshToken: resRefresh.refresh_token,
            });
            error.config._request = true;
            return requestAxios.request(error.config);
          } else {
            loginTimeout();
          }
        }
      } else {
        loginTimeout();
      }
    } else {
      console.log("error", error);
      Vue.prototype.$message.error({
        message: error?.response?.data?.message,
        duration: 3000,
      });
      return res;
    }
  }
);

const webview = web.webView;

function loginTimeout() {
  if (timeoutToast) {
    timeoutToast = false;
    Vue.prototype.$message.error({
      message: "登录超时，请重新登录",
      duration: 3000,
    });
    store.dispatch("user/logout");
    webview.postMessage({
      data: {
        type: "error",
        action: "401",
      },
    });
    window.top.postMessage(
      {
        type: "error",
        action: "401",
      },
      "*"
    );
    setTimeout(() => {
      timeoutToast = true;
    }, 2000);
  }
}

export default requestAxios;

const download = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  responseType: "arraybuffer",
});

download.interceptors.request.use(useToken, (err) => {
  return Promise.reject(err);
});

export { download };
