const getters = {
  token: (state) => state.user.token,
  refreshToken: (state) => state.user.refreshToken,
  config: (state) => state.user.config,
  menus: (state) => state.user.menus,
  isReloadData: (state) => state.global.isReloadData,
  userInfo: (state) => state.user.userInfo,
  currentTenantId: (state) => state.user.config.tenantId,
  authCenter: (state) => state.user.authCenter,
  permissions: (state) => state.user.permissions,
};
export default getters;
