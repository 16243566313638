import { getCurrentSet } from "@/api/user";
import router, { addRoutes } from "@/router";

const state = {
  token: "",
  refreshToken: "",
  menus: [],
  userInfo: null,
  config: null,
};

const mutations = {
  setToken: (state, { token, refreshToken }) => {
    state.token = token;
    state.refreshToken = refreshToken;
  },
  setMenus: (state, menus) => {
    state.menus = menus;
    console.log("state.menus", state.menus);
  },
  setPermissions: (state, permissions) => {
    state.permissions = permissions;
  },
  setUserInfo: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  setConfig: (state, config) => {
    state.config = config;
  },
};

const actions = {
  setToken({ commit }, { token, refreshToken }) {
    commit("setToken", { token, refreshToken });
  },

  logout({ commit }) {
    commit("setToken", { token: "", refreshToken: "" });
  },

  // 获取应用配置
  async getCurrentSet({ commit, dispatch }) {
    const pageConfigRes = await getCurrentSet();
    if (!(pageConfigRes && pageConfigRes.success)) {
      //获取失败，退出登录
      dispatch("logout");
    } else {
      const config = pageConfigRes.data;
      commit("setConfig", config);
      addRoutes(config);
    }
  },
};

const getters = {
  // 映射state所有属性为getter
  ...Object.assign(
    ...Object.keys(state).map((k) => {
      return {
        [k]: (s) => s[k],
      };
    })
  ),
};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
