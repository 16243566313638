<template>
    <component :is="componentLibName" :options="options" class="chart-wrapper" ref="chartRef" @OnEcharts="OnEcharts"
        :routerQuery="routerQuery" :dynamicParams="dynamicParams" :uid="uid" @click="onClick" :componentName="componentName"
        @setPageTitle="setPageTitle" @updateAxisPointer="updateAxisPointer">
    </component>
</template>

<script>
import _ from "lodash";
import ECharts from "@/components/ECharts";
import Highcharts from "@/components/Highcharts";
import JSUI from "@/components/JSUI";
import IsEmpty from "@/components/IsEmpty";
import IsLoading from "@/components/IsLoading";
import JsWord from "@/components/JsWord";

export const CHART_LIBRARY = {
    ECHARTS: "E_CHARTS",
    HIGHCHARTS: "HIGH_CHARTS",
    CUSTOM: "CUSTOM",
    MEDIA: "MEDIA",
    TEXT: "TEXT",
};

const CHART_LIBRARY_MAP = {
    E_CHARTS: "ECharts",
    HIGH_CHARTS: "Highcharts",
    CUSTOM: "JSUI",
    TEXT: "JsWord",
    MEDIA: "JsMedia",
};

const GLOBAL_CHART_OPTIONS = {
    E_CHARTS: undefined,
    HIGH_CHARTS: {
        credits: {
            enabled: false
        }
    }
};

// 图表解析组件，内部整合各种组件库
export default {
    name: "ComponentInstance",
    components: {
        ECharts,
        Highcharts,
        JSUI,
        IsEmpty,
        IsLoading,
        JsWord
    },
    props: {
        uid: {
            type: String
        },
        // 组件类型
        componentType: {
            type: String,
            default: ""
        },
        // 图表库，默认为echarts
        library: {
            type: String,
            default: CHART_LIBRARY.ECHARTS
        },
        source: [String, Array], //原始数据：[{x:"2018", y: 199, s: "盈利"}]
        config: { type: Object, default: () => { } }, //{colors:[],categories: []}
        dataMap: {
            type: Object,
            default: () => ({ x: "x", y: "y", s: "s", u: "u" }) //x: x轴值字段名,y: 值字段名,s: 系列字段名,u: 唯一标识字段名
        }, //原始数据映射关系
        getOptions: { type: Function }, //(source,config) => { return {}} 返回option
        componentName: { type: String },// 自定义组件会传入组件名
        context: { type: Object },
        loading: { type: Boolean, default: false },
        colors: { type: Array },
        routerQuery: { type: Object }, // 路由参数
        dynamicParams: { type: Object }, // 页面参数
    },
    data() {
        return {
            options: { source: [] }
        };
    },
    watch: {
        source: {
            handler() {
                this.$nextTick(() => {
                    this.initOption();
                });
            },
            deep: true,
            immediate: true
        },
    },
    computed: {
        isNoDataCpt() {
            return !this.source || !this.source?.length
        },
        //组件库名
        componentLibName() {
            let result = "IsLoading"
            if (this.loading) {
                result = "IsLoading";
            } else {
                let isNoData = this.isNoDataCpt;
                result = isNoData ? "IsEmpty" : CHART_LIBRARY_MAP[this.library];
            }
            return result;
        }
    },
    mounted() {
    },
    methods: {
        setPageTitle(pageTitle) {
            this.$emit("setPageTitle", pageTitle)
        },
        onClick(params) {
            this.$emit("click", params)
        },
        initOption() {
            if (!this.componentType || this.componentType === "CHART") {
                if (typeof this.getOptions === "function" && this.source) {
                    const { series, categories } = this.parseChartData(
                        this.source,
                        this.dataMap
                    );
                    if (series && series.length) {
                        this.options = _.merge({}, this.mixinGlobalOptions(
                            this.getOptions({ series, categories }, this.config, this.context)
                        ), this.config);
                    }
                    this.$forceUpdate();
                }
            } else {
                this.options = {
                    source: this.source,
                    colors: this.colors
                }
            }
        },
        // 加入各个图表库自己的全局配置
        mixinGlobalOptions(opt) {
            return opt && _.merge(opt, GLOBAL_CHART_OPTIONS[this.library]);
        },
        OnEcharts(res) {
            this.$emit("OnEcharts", res);
        },
        updateAxisPointer(res) {
            this.$emit("updateAxisPointer", res);
        },
        //解析原始数据
        parseChartData(source = [], { x, y, s, u }) {
            const series = [];
            const categories = [];
            const xKey = x || "x";
            const yKey = y || "y";
            const sKey = s || "s";
            const uKey = u || "u";
            source.forEach(i => {
                if (categories.indexOf(i[xKey]) === -1) {
                    categories.push(i[xKey]);
                }
                const serie = series.find(s => s.name === i[sKey]);
                if (serie) {
                    serie.data.push(i[yKey]);
                    if (serie.uids && serie.uids.length) {
                        serie.uids.push(i[uKey]);
                    }
                } else {
                    series.push({
                        name: i[sKey],
                        data: [i[yKey]],
                        uids: i[uKey] ? [i[uKey]] : []
                    });
                }
            });
            return {
                series,
                categories
            };
        },
        parseChartDataForCustomChart(source = [], { x, y, s, u }) {
            const xKey = x || "x";
            const yKey = y || "y";
            const sKey = s || "s";
            const uKey = u || "uid";
            return source.map(i => (
                {
                    x: i[xKey],
                    y: i[yKey],
                    s: i[sKey],
                    u: i[uKey],
                }
            ))
        }
    },
};
</script>

<style lang="scss" scoped>
.chart-wrapper {
    width: 100%;
    height: 0;
    flex: 1;
}
</style>
