import { download } from "./index";

// 获取页面配置
export function getChartConfig(url) {
  const timestamp = new Date().getTime();
  return download({
    url: `${url}?timestamp=${timestamp}`,
    method: "get",
  });
}
