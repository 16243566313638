<template>
  <div class="c-container">
    <van-tabs v-model="activeTab" class="c-van-tabs" color="rgba(5, 192, 238, 1)"
      background="linear-gradient(180deg, #2769B3 0%, #002653 100%)" title-inactive-color="rgba(195, 217, 248, 1)"
      title-active-color="rgba(255, 255, 255, 1)" swipeable animated>
      <van-tab v-for="(tab, i) in pageList" :key="i" :title="tab.name" class="c-van-tab">
        <ComponentParser :pageList="pageList" :pageId="tab.id"></ComponentParser>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
// 这是一个临时解决方案，在layout用 tab 来切换页面

import { mapGetters } from "vuex";
import ComponentParser from '@/components/ComponentParser/index.vue'

export default {
  name: "AppMain",
  components: { ComponentParser },
  directives: {
  },
  data() {
    return {
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters([
      "config",
    ]),
    pageList() {
      return this.config.pageList
    },
  },
  watch: {
  },
  created() {

  },
  updated() {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style>
.van-tabs__wrap {
  position: fixed;
  width: 100%;
  z-index: 1;
}

.van-tabs__content {
  margin-top: 44px;
}
</style>
<style lang="scss" scoped>
.c-container {
  background-color: rgb(0, 38, 83);
  height: 100vh;

  .c-van-tabs {
    // position: fixed;
    width: 100vw;
    // height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .c-van-tab {
      width: 100%;
      // height: calc(100vh - 120px);
      overflow-y: scroll;
      background-color: rgb(0, 38, 83);
    }
  }

}
</style>

<style></style>
