<template>
  <div class="btn-wrap" :class="[positionClassCpt]">
    <div v-for="(i, iNo) of items" :key="iNo" :class="isActiveHandler(i, iNo)" class="btn-item"
      @click="itemClick(i, iNo)">{{ i.text }}</div>
  </div>
</template>

<script>
export default {
  name: "TabBtn",
  props: {
    value: {},
    right: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => [
        { text: '按季', value: '0' },
        { text: '按年', value: '1' },
      ]
    }
  },
  data() {
    return {
      modelValue: undefined,
    };
  },
  watch: {
    value: {
      handler(v) {
        this.modelValue = v
      },
      immediate: true
    },
    items: {
      handler(v) {
        setTimeout(() => {
          if (v instanceof Array && v.length) {
            if (!v.some(i => i.value === this.modelValue)) {
              this.modelValue = Object.prototype.hasOwnProperty.call(v[0], 'value') ? v[0].value : 0;
            }
          }
        }, 0);
      },
      immediate: true
    }
  },
  mounted() {

  },
  methods: {
    isActiveHandler(i, index) {
      let value = Object.prototype.hasOwnProperty.call(i, 'value') ? i.value : index;
      return value === this.modelValue ? 'is-active' : ''
    },
    itemClick(i, index) {
      let value = Object.prototype.hasOwnProperty.call(i, 'value') ? i.value : index;
      this.modelValue = value;
      this.$emit('change', i);
      this.$emit('input', value);
    }
  },
  computed: {
    positionClassCpt() {
      if (this.left === true) {
        return 'is-to-left'
      } else if (this.right === true) {
        return 'is-to-right'
      } else {
        return ''
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.is-to-right {
  right: 5px;
}

.is-to-left {
  left: 5px;
}

.btn-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  display: flex;
  font-size: 14px;
}

.btn-item {
  // opacity: 0.6;
  cursor: pointer;
  user-select: none;
  background: no-repeat center / 100% 100%;
  background-image: url('./assets/images/tab-btn-bg-0.png');
  padding: 0 .8em 0 1em;
  line-height: 2em;
  height: 2em;
  color: #6dbce9;

  &+& {
    margin-left: 8px;
  }

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.9;
  }

  &.is-active {
    color: #dedede;

    background-image: url('./assets/images/tab-btn-bg-1.png');
  }
}
</style>
