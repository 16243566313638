<template>
    <div class="component-parser-container" :style="componentParserStyle">
        <ComponentWrapper v-for="(b, bNo) of blocks" :key="bNo" v-bind="b.blockOption" :title="b.blockOption.title"
            :active="b.active" :tabBtnsInfo="b.tabs" @changeTab="changeTab(...arguments, b)">
            <ComponentInstance v-bind="b.chartOption" @click="(params) => onComponentClick(b.id, params)"
                @setPageTitle="setPageTitle" :uid="`chart_${bNo}`">
            </ComponentInstance>
        </ComponentWrapper>
    </div>
</template>
<!-- 组件解析器 -->
<script>
import ComponentInstance from "./ComponentInstance";
import ComponentWrapper from "./ComponentWrapper";
import { getChartConfig } from "@/api/chart";
import { ab2str } from "@/utils/tools";
import request from "@/api";
import _ from 'lodash';

const HIGHCHART_AXIS_TITLE_POSITION_MAP = {
    LEFT_TOP: "high",
    LEFT: "middle",
    LEFT_BOTTOM: "low",
}
const ECHART_AXIS_TITLE_POSITION_MAP = {
    LEFT_TOP: "end",
    LEFT: "center",
    LEFT_BOTTOM: "start",
}

const ECHART_CONFIG = {
    legend: {
        show: false,
    },
};

const HIGHCHART_CONFIG = {
    legend: {
        enabled: false,
    },
};


export default {
    name: 'ComponentParser',
    components: {
        ComponentInstance,
        ComponentWrapper,
    },
    props: {
        pageId: { type: String, default: "" },//页面id
        pageList: { type: Array, default: () => [] },//页面配置
    },
    data() {
        return {
            blocks: [],
            dynamicParams: {}, // 页面动态参数
            componentRelatedParamsMap: {}, // 图表关联变量对象，如果目标变量发生改变，通知图表重新获取数据
            componentParserStyle: {}, // 页面样式
            pageConfig: null, //页面配置
            navBarBg: "#0E438F",
            pageTitle: "",
        }
    },
    async created() {
    },
    mounted() {

    },
    computed: {
        // 此处以componentRelatedParamsMap中的变量名为key，变量的值为value，目的是为了监听这些变量的值的变化
        componentRelatedParamsMapCpt() {
            return Object.assign(
                {},
                ...Object.keys(this.componentRelatedParamsMap).map((k) => ({
                    [k]: this.dynamicParams[k],
                }))
            );
        },

    },
    watch: {
        pageId: {
            handler(val) {
                if (val) {
                    this.loadingPageComponents(val)
                }
            },
            immediate: true
        },
        componentRelatedParamsMapCpt: {
            handler(newVal, oldVal) {
                // 对比数组内容，找到变化的数值的下标，通知对应的图表重新请求数据
                if (newVal && oldVal) {
                    Object.keys(newVal).map((i) => {
                        if (oldVal[i] !== undefined && !this._.isEqual(newVal[i], oldVal[i])) {
                            this.updateComponentData(this.componentRelatedParamsMap[i]);
                        }
                    });
                }
            },
            deep: true,
        },
    },
    methods: {
        // 切换按钮
        changeTab(v, info) {
            info.active = v;
            info.blockOption = info.option[v].blockOption;
            info.chartOption = info.option[v].chartOption;
        },
        setPageTitle(pageTitle) {
            this.pageTitle = pageTitle
        },
        // 组件点击事件,id 为组件的id
        onComponentClick(id, params) {
        },
        // 重新获取组件数据
        updateComponentData(componentIds) {
            componentIds.forEach((componentId) => {
                const blockIndex = this.blocks.findIndex((b) => b.id === componentId);
                this.getComponentApiData(this.blocks[blockIndex].api, this.blocks[blockIndex].method).then((res) => {
                    this.blocks[blockIndex].chartOption.source = res;
                });
            });
        },
        // 获取组件配置
        getChartConfig(componentSetUrl, componentType) {
            if (!componentType || componentType === "CHART") {
                return getChartConfig(componentSetUrl);
            } else {
                //自定义组件的componentSetUrl为组件名，直接使用，不用请求组件文件
                return Promise.resolve();
            }
        },
        // 加载页面组件
        loadingPageComponents(pageId) {
            const pageConfig = this.pageList.find(
                (p) => p.id === pageId
            );
            if (pageConfig) {
                const promiseList = [];
                this.pageConfig = pageConfig;
                this.loadingComponentParserStyle(pageConfig);
                for (let i = 0; i < pageConfig.componentList.length; i++) {
                    const componentConfig = pageConfig.componentList[i];
                    if (!componentConfig.show) {
                        continue
                    }
                    let config = {};
                    if (componentConfig.componentLib === "E_CHARTS") {
                        config = _.merge({}, ECHART_CONFIG, {
                            legend: { show: componentConfig.showLegend },
                            yAxis: this.handleEChartsUnit(componentConfig.units)
                        });
                    } else if (componentConfig.componentLib === "HIGH_CHARTS") {
                        config = _.merge({}, HIGHCHART_CONFIG, {
                            legend: { enabled: componentConfig.showLegend },
                            yAxis: this.handleHighChartsUnit(componentConfig.units)
                        });
                    }
                    const indexInBlocks = this.blocks.findIndex((b) => b.id === componentConfig.id);
                    const blockConfig = {
                        id: componentConfig.id,
                        tabs: [],
                        active: 0,
                        options: [],
                        api: componentConfig.dataSource.api,
                        method: componentConfig.dataSource.method,
                        blockOption: {
                            ...componentConfig,
                            ...{
                                transitionClass: componentConfig.animation,
                                top: componentConfig.topPosition,
                                right: componentConfig.rightPosition,
                                bottom: componentConfig.bottomPosition,
                                left: componentConfig.leftPosition,
                                height: componentConfig.height,
                                width: componentConfig.width,
                                title: componentConfig.name,
                                interval: componentConfig.interval,
                            }
                        },
                        chartOption: {
                            componentType: componentConfig.componentType,
                            library: componentConfig.componentLib,
                            source: [],
                            loading: true,
                            colors: componentConfig.colors,
                            config: config
                        },
                    };
                    let promiseItem = this.getChartConfig(
                        componentConfig.componentSetUrl,
                        componentConfig.componentType
                    )
                        .then((res) => {
                            if (!componentConfig.componentType || componentConfig.componentType === "CHART") {
                                let chartConfig = {};
                                const chartConfigStr = ab2str(res?.data);
                                // 此处会给chartConfig赋值，chartConfigStr内容为 chartConfig={library:...}
                                eval(chartConfigStr);
                                blockConfig.chartOption.getOptions = chartConfig.getOptions;
                                return chartConfig;
                            } else {
                                blockConfig.chartOption.componentName = componentConfig.componentSetUrl;
                                return;
                            }
                        })
                        .then((chartConfig) => {
                            const sourceType = componentConfig.dataSource.sourceType || "API";
                            if (sourceType === "API" && componentConfig.dataSource.api) {
                                return this.getChartSource(componentConfig.dataSource.api, componentConfig.id, componentConfig.dataSource.method);
                            } else if (sourceType === "STATIC") {
                                componentConfig.dataSource.x = "x";
                                componentConfig.dataSource.y = "y";
                                componentConfig.dataSource.s = "s";
                                return componentConfig.dataSource.staticData;
                            } else {
                                return chartConfig?.source;
                            }
                        })
                        .then((source) => {
                            blockConfig.chartOption.loading = false;
                            blockConfig.chartOption.source = source;
                            blockConfig.chartOption.dataMap = componentConfig.dataSource;
                        })
                        .catch((error) => {
                            console.error("图表加载出错", error);
                            blockConfig.chartOption.loading = false;
                        });
                    promiseList.push(promiseItem);
                    if (indexInBlocks !== -1) {
                        this.blocks.splice(indexInBlocks, 1, blockConfig);
                    } else {
                        this.blocks.push(blockConfig);
                    }
                }
                this.polyComponents();
                return Promise.all(promiseList).catch((e) => {
                    console.error(e);
                });
            } else {
                return Promise.reject("none config found");
            }
        },
        //聚合组件
        polyComponents() {
            let newArray = [];
            let newList = [];
            let tempArray = [];
            this.blocks.forEach((ele) => {
                let isRepeat = false;
                if (
                    ele.blockOption.title &&
                    ele.blockOption.title.search("-") !== -1
                ) {
                    if (tempArray.indexOf(ele.blockOption.title.split("-")[0]) > -1) {
                        isRepeat = true;
                    }
                    tempArray.push(ele.blockOption.title.split("-")[0]);
                    newArray.push({
                        title: ele.blockOption.title.split("-")[0],
                        isRepeat: isRepeat,
                        name: ele.blockOption.title.split("-"),
                        id: ele.id,
                    });
                } else {
                    newList.push(ele);
                }
            });
            let tabsArr = [];
            // 组件按钮列表
            newArray.forEach((k) => {
                if (!k.isRepeat && tabsArr.indexOf(k) == -1) {
                    tabsArr.push({
                        ids: [k.id],
                        title: k.title,
                        tabs: [
                            {
                                id: k.id,
                                text: k.name[1],
                                value: 0,
                            },
                        ],
                    });
                } else {
                    for (let s = 0; s < tabsArr.length; s++) {
                        if (tabsArr[s].title == k.title) {
                            let temp = {
                                id: k.id,
                                text: k.name[1],
                                value: tabsArr[s].tabs[tabsArr[s].tabs.length - 1].value + 1,
                            };
                            tabsArr[s].tabs.push(temp);
                            tabsArr[s].ids.push(k.id);
                        }
                    }
                }
            });

            // 通过按钮列表配置图表数据
            for (let i = 0; i < tabsArr.length; i++) {
                let params = {
                    id: tabsArr[i].ids[0],
                    tabsTitle: tabsArr[i].title,
                    tabs: tabsArr[i].tabs,
                    active: 0,
                    option: [],
                    blockOption: {},
                    chartOption: {},
                };
                for (let t = 0; t < tabsArr[i].ids.length; t++) {
                    for (let m = 0; m < this.blocks.length; m++) {
                        if (this.blocks[m].id == tabsArr[i].ids[t]) {
                            this.blocks[m].blockOption.title = params.tabsTitle;
                            params.option.push(this.blocks[m]);
                        }
                    }
                }
                params.blockOption = params.option[0].blockOption;
                params.chartOption = params.option[0].chartOption;
                params.blockOption.title = params.tabsTitle;
                newList.push(params);
                console.log(params);
            }
            console.log(newList);
            this.blocks = newList;
        },
        // 读取y坐标轴单位，转换为highchart对应的属性
        handleHighChartsUnit(units) {
            const result = [];
            units.forEach(u => {
                result.push({
                    title: {
                        text: u.text,
                        align: HIGHCHART_AXIS_TITLE_POSITION_MAP[u.position] || ""
                    },
                })
            })
            return result
        },
        // 读取y坐标轴单位，转换为echart对应的属性
        handleEChartsUnit(units) {
            const result = [];
            units.forEach(u => {
                result.push({
                    name: u.text,
                    nameLocation: ECHART_AXIS_TITLE_POSITION_MAP[u.position] || ""
                })
            })
            return result
        },
        // 加载页面样式
        loadingComponentParserStyle(pageConfig) {
            this.componentParserStyle = {
                padding: pageConfig.padding && pageConfig.padding.join(" "),
                backgroundColor: pageConfig.backgroundColor
            }
        },
        // 获取源数据
        async getChartSource(api, componentId, method) {
            this.bindPageParamsWithChart(api, componentId);
            const res = await this.getComponentApiData(api, method);
            return res;
        },
        // 获取数据源类型为api的组件的数据
        async getComponentApiData(api, method) {
            const { url, params } = this.parseApi(api);
            let res;
            if (url) {
                if (!method || method.toUpperCase() === "GET") {
                    res = await request.get(url, { params: params });
                } else if (method.toUpperCase() === "POST") {
                    res = await request.post(url, params)
                }
                return res?.data;
            } else {
                return []
            }
        },
        // 解析api，匹配占位符 ${}
        parseApi(api) {
            const tmp = api.split("?")
            const url = tmp[0];
            const paramsStr = tmp[1];
            let params = {};
            var paramKeys = [];
            const reg = /\$\{([A-Za-z | _])+\}/g;
            const clearReg = /\$\{(.*)+\}/;
            if (paramsStr) {
                const paramsStrList = paramsStr.split("&");
                paramsStrList.forEach(i => {
                    const singleParamArray = i.split("="); // ['key','value']
                    const placeholder = singleParamArray[1] && singleParamArray[1].match(reg);
                    if (placeholder?.length) {
                        placeholder.forEach(j => {
                            let paramKey = j.match(clearReg)[1]
                            params[paramKey] = this.dynamicParams[paramKey] || ""
                        })
                    } else {
                        params[singleParamArray[0]] = singleParamArray[1]
                    }
                })
            }
            return { url, params };
        },
        // 绑定页面上变量和组件请求参数之间的关联关系，页面变量改变时通知组件重新获取数据
        bindPageParamsWithChart(api, componentId) {
            var params = [];
            //api中的变量以${xxx}的形式表示，通过正则匹配出这些变量
            const reg = /\$\{([A-Za-z | _])+\}/g;
            const clearReg = /\$\{(.*)+\}/;
            params = api.match(reg);
            if (params && params.length) {
                const componentRelatedParamsMap = this._.cloneDeep(
                    this.componentRelatedParamsMap
                );
                params.forEach((i) => {
                    const param = i.match(clearReg)[1];
                    const mapItem = componentRelatedParamsMap[param];
                    // 如果该参数已绑定组件，则继续添加，否则新建一个数组存放当前组件id
                    if (mapItem) {
                        componentRelatedParamsMap[param].push(componentId);
                    } else {
                        componentRelatedParamsMap[param] = [componentId];
                    }
                });
                this.componentRelatedParamsMap = componentRelatedParamsMap;
            }
        },
    },
}
</script>

<style lang='scss' scoped>
.component-parser-container {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    min-height: 100%;
}
</style>