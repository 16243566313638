import request from "./index";

// 获取页面配置
export function getCurrentSet(params) {
  return request({
    url: "/service-admin/screenSet/getCurrentSet",
    method: "get",
    params,
  });
}
