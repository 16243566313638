import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import Layout from "@/layout";

Vue.use(Router);
// 路由懒加载
export const getComponent = (view) => {
  // 路由懒加载
  return () => {
    store.commit("global/TOGGLE_ROUTE_LOADING", true);
    return {
      component: Promise.all([
        import(`@/views/${view}`),
        new Promise((c) => setTimeout(c, 0)),
      ]).then((d) => {
        store.commit("global/TOGGLE_ROUTE_LOADING", false);
        return d[0];
      }),
    };
  };
  // }
};

const constantRoutes = [
  {
    path: "/",
    name: "layout",
    component: Layout,
    children: [],
    meta: {},
  },
  {
    path: "/404",
    name: "404",
    component: getComponent("error/404"),
    hidden: true,
    meta: {
      title: "Not found",
    },
  },
  {
    path: "/401",
    name: "401",
    component: getComponent("error/401"),
    hidden: true,
    meta: {
      title: "未授权",
    },
  },
];
const createRouter = () =>
  new Router({
    // IIS发布使用默认的hash模式
    // IIS使用history请参考 https://router.vuejs.org/zh/guide/essentials/history-mode.html#internet-information-services-iis
    mode: "history",
    // scrollBehavior: () => ({ y: 100 }),
    routes: constantRoutes,
  });

const router = createRouter();
// autoCenter.initVueRoute(router);

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

// 添加路由
export function addRoutes(config) {}

// 登出
export function toLogout() {
  store.dispatch("user/logout");
}

let first = true;
// 路由全局前置守卫
router.beforeEach(async (to, from, next) => {
  if (to.name === "401") {
    next();
  } else {
    let token = store.getters.token;
    // token和refreshToken可能在url中
    if (to.query.token && to.query.refreshToken) {
      token = to.query.token;
      store.dispatch("user/setToken", {
        token: to.query.token,
        refreshToken: to.query.refreshToken,
      });
    }
    if (!token) {
      next({
        name: "401",
      });
    } else if (token && first) {
      first = false;
      await store.dispatch("user/getCurrentSet");
      next({
        ...to,
        replace: true,
      });
    } else {
      next();
    }
  }
});

export default router;
