<template>
  <component class="js-ui-wrap" ref="js-ui" :is="componentName" @click="onClick" v-bind="options"
    @setPageTitle="setPageTitle" :routerQuery="routerQuery" :dynamicParams="dynamicParams"></component>
</template>

<script>
import DataPanel from "../packages/DataPanel"

export default {
  name: "JSUI",
  components: {
    DataPanel,
  },
  props: {
    options: {
      type: Object,
      default: () => ({
        source: [],
      }),
    },
    componentName: {
      type: String,
      require: true,
    },
    routerQuery: {
      type: Object
    },
    dynamicParams: {
      type: Object
    }
  },
  data() {
    return {
    };
  },
  watch: {
    options: {
      handler(val) {
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
  },
  methods: {
    setPageTitle(pageTitle) {
      this.$emit("setPageTitle", pageTitle)
    },
    //事件传递
    onClick(params) {
      this.$emit("click", params);
    },
  },
};
</script>

<style lang="scss" scoped>
.js-ui-wrap {
}
</style>
