<template>
  <div class="box-wrap">
    <svg class="icon-wrap animate__animated" viewBox="0 0 1024 1024" version="1.1" width="200" height="200">
      <path
        d="M708.2 194.6c-4.8 1.1-9.9-0.2-13.5-3.6-2.7-2.5-4.2-5.9-4.2-9.6 0-3.6 1.5-7.1 4.2-9.6l37.2-37.3c2.5-2.7 5.9-4.2 9.6-4.2 3.7 0 7.1 1.5 9.6 4.2 2.7 2.5 4.2 5.9 4.2 9.6 0 3.6-1.5 7.1-4.2 9.6L713.9 191c-2.3 2.3-3.4 3.4-5.7 3.4M791.6 281.6c-1.7 0.9-3.7 1.3-5.7 1.2l-47.3-5.6c-7.5-0.6-13-7.2-12.4-14.7 0.6-7.5 7.2-13 14.7-12.4l47.3 4.5c7.5 0.7 13 7.2 12.4 14.7 0 5.6-3.6 10.6-9 12.3M628.1 176.6c-3.4 1.2-7.2 0.8-10.4-0.9-3.2-1.7-5.5-4.7-6.5-8.1l-15.8-45.2c-2.4-6.8 1.1-14.4 7.9-17 3.4-1.2 7.2-0.9 10.4 0.8 3.2 1.7 5.6 4.6 6.6 8.1l15.8 45.2c1.4 3.3 1.4 7-0.1 10.2-1.5 3.3-4.4 5.7-7.9 6.9M451.2 915.6H279.8c-6.2 0-11.3-5.1-11.3-11.3 0-6.2 5.1-11.3 11.3-11.3h171.3c3.1-0.2 6 0.9 8.2 3.1 2.2 2.2 3.3 5.2 3.1 8.2 0 3-1.2 5.9-3.3 8-2.1 2.1-4.9 3.3-7.9 3.3z m-171.4-60.1c-6.2 0-11.3-5.1-11.3-11.3 0-6.2 5.1-11.3 11.3-11.3l381.1-1.1c6.2 0 11.3 5.1 11.3 11.3 0 6.2-5.1 11.3-11.3 11.3l-381.1 1.1z m0 0"
        p-id="3393"></path>
      <path
        d="M208.5 390c6.8-2.4 11.6-8.6 12.3-15.8-13-40.7-26-81.5-39.1-122.2-2.4-7.6-11.5-11.5-20.3-8.7-8.8 2.8-14.1 11.2-11.6 18.8 13 41.1 26 82.2 39.1 123.4 5.1 5.2 12.8 7 19.6 4.5z m103.9 320.5c6.8-2.4 11.6-8.6 12.3-15.8-13-40.7-26-81.5-39.1-122.2-2.4-7.6-11.5-11.5-20.3-8.7-8.8 2.8-14.1 11.2-11.6 18.8 13 41.1 26 82.2 39.1 123.4 5 5.3 12.7 7 19.6 4.5z m28 78.8c6.8-2.4 11.6-8.6 12.3-15.8-13-40.7-0.3-0.6-13.3-41.3-2.4-7.6-11.5-11.5-20.3-8.7-8.8 2.8-14.1 11.2-11.6 18.8 13 41.1 0.3 1.4 13.3 42.5 5.1 5.3 12.8 7 19.6 4.5z m-7.3 2.2c41.2-12.7 1.3-0.6 42.5-13.3 7.6-2.3 11.6-11.5 8.9-20.3-2.7-8.8-11.1-14.1-18.7-11.8-40.9 12.5-0.6 0.1-41.5 12.7-5.5 4.7-7.8 12.1-6 19.1 1.8 7 7.6 12.3 14.8 13.6z m-73.1-241c6.8-2.4 11.6-8.6 12.3-15.8-13-40.7-26-81.5-39.1-122.2-2.4-7.6-11.5-11.5-20.3-8.7-8.8 2.8-14.1 11.2-11.6 18.8 13 41.1 26 82.2 39.1 123.4 5.1 5.2 12.8 6.9 19.6 4.5z m479.5 99.6c40.7-14.3 81.4-28.5 122.1-42.8 7.6-2.6 11.1-11.9 8.1-20.6-3-8.7-11.6-13.7-19.1-11.1-40.4 14-80.8 28.1-121.2 42.1-5.3 4.9-7.3 12.4-5.2 19.3 2.1 7 8.1 12.1 15.3 13.1zM419.8 761.3c40.7-14.3 81.4-28.5 122.1-42.8 7.6-2.6 11.1-11.9 8.1-20.6-3-8.7-11.6-13.7-19.1-11.1-40.4 14-80.8 28.1-121.2 42.1-5.3 4.9-7.3 12.4-5.2 19.3 2.1 6.9 8.1 12.1 15.3 13.1zM579.3 707c40.7-14.3 81.4-28.5 122.1-42.8 7.6-2.6 11.1-11.9 8.1-20.6-3-8.7-11.6-13.7-19.1-11.1-40.4 14-80.8 28.1-121.2 42.1-5.3 4.9-7.3 12.4-5.2 19.3 2 7 8 12.1 15.3 13.1z m228.6-102.5M406.7 574.8l42.8-82.9 96.4 49.8c4 2.1 8.6 2.5 12.9 1.1l0.4-0.1c4.4-1.5 8-4.8 9.9-9l102.6-226.5c3.9-8.5 0.1-18.6-8.4-22.4-8.5-3.8-18.6-0.1-22.4 8.4l-95.3 210.2-95.6-49.3c-8.3-4.3-18.5-1-22.8 7.3l-50.6 97.9c-4.3 8.3-1 18.5 7.3 22.8 8.3 4.2 18.5 1 22.8-7.3z m0 0"
        p-id="3394"></path>
    </svg>
    <span class="content-wrap">暂无数据</span>
  </div>
</template>

<script>
export default {
  name: "IsEmpty",
  props: { options: {} },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
.box-wrap {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 5%;
  display: flex;
  color: rgba(0, 150, 228, 0.3);
  fill: rgba(0, 150, 228, .3);
  flex-direction: column;
  cursor: pointer;
}

.center-wrap {
  margin: auto;
}

.icon-wrap {
  // background: url('../../assets/images/chart-wrap/no-data.svg');
  width: 100%;
  height: 100%;
}

.content-wrap {
  padding-top: 5px;
  padding-bottom: 20px;
  text-align: center;
}
</style>
