const state = {
    isReloadData: true,
    title: "",
    bread: [],
    loading: true,
    routeLoading: false,
};

export default {
    name: "global",
    namespaced: true,
    state: state,
    getters: {
        loading(state) {
            return state.loading;
        },
        routeLoading(state) {
            return state.routeLoading;
        },
    },
    mutations: {
        TOGGLE_ROUTE_LOADING(state, flag = !state.routeLoading) {
            state.routeLoading = flag;
        },
        TOGGLE_LOADING(state, flag = !state.loading) {
            state.loading = flag;
        },
        SET_RELOAD_DATA(state, isReloadData) {
            state.isReloadData = isReloadData;
        },
        SET_HEADER_TITLE(state, title) {
            state.title = title;
        },
        ADD_BREAD_ITEM(state, breadItem) {
            if (breadItem.id === state.bread[state.bread.length - 1].id) {
                return;
            }
            state.bread.push(breadItem);
            if (breadItem) {
                sessionStorage.setItem("newMenu", JSON.stringify(state.bread));
            }
        },
        UPDATE_BREAD(state, bread) {
            state.bread = bread;
            //   console.log('刷新之后想要得到的最新路径',bread);
        },
    },
    actions: {
        initReloadDataLoop({ commit }) {
            setInterval(() => {
                commit("SET_RELOAD_DATA", false);
                setTimeout(() => {
                    commit("SET_RELOAD_DATA", true);
                }, 20);
            }, 6000);
        },
        setReloadData({ commit }, isReloadData) {
            commit("SET_RELOAD_DATA", isReloadData);
        },
        addBreadItem({ commit }, breadItem) {
            commit("ADD_BREAD_ITEM", breadItem);
        },
        updateBread({ commit }, bread) {
            commit("UPDATE_BREAD", bread);
        },
    },
};