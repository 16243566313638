<template>
  <div class="box-wrap">
    <svg t="1649323475637" class="icon-wrap" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
      p-id="4312" width="200" height="200">
      <path
        d="M563.2 819.2a102.4 102.4 0 1 1 0 204.8 102.4 102.4 0 0 1 0-204.8z m-320.4608-153.6a128 128 0 1 1 0 256 128 128 0 0 1 0-256z m592.7936 25.6a102.4 102.4 0 1 1 0 204.8 102.4 102.4 0 0 1 0-204.8zM947.2 477.1328a76.8 76.8 0 1 1 0 153.6 76.8 76.8 0 0 1 0-153.6zM128 307.2a128 128 0 1 1 0 256 128 128 0 0 1 0-256z m782.6432-40.6016a51.2 51.2 0 1 1 0 102.4 51.2 51.2 0 0 1 0-102.4zM409.6 0a153.6 153.6 0 1 1 0 307.2 153.6 153.6 0 0 1 0-307.2z m384 153.6a25.6 25.6 0 1 1 0 51.2 25.6 25.6 0 0 1 0-51.2z"
        p-id="4313"></path>
    </svg>
    <span class="content-wrap">加载中</span>
  </div>
</template>

<script>
export default {
  name:"IsLoading",
  props: { options: {} },
};
</script>

<style lang="scss" scoped>
.box-wrap {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  color: rgba(0, 150, 228, 0.7);
  fill: rgba(0, 150, 228, .7);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.center-wrap {
  margin: auto;
}

.icon-wrap {
  width: 100%;
  height: 100%;
  max-width: 70px;
  max-height: 70px;
  animation: rolling 1.2s ease-out infinite;
}

.content-wrap {
  padding-top: 5px;
  padding-bottom: 20px;
  text-align: center;
}

@keyframes rolling {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
