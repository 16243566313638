<template>
    <div class="component-wrapper-container" :style="wrapperStyle">
        <div v-if="title" class="block-title">
            <TabBtn v-if="tabBtnsInfo" :items="tabBtnsInfo" @input="(value) => toggleDimension(value)" :value="active" />
            <span>{{ title }}</span>
        </div>
        <slot />
    </div>
</template>
  
<script>
import TabBtn from "@/components/TabBtn";

export default {
    props: {
        tabBtnsInfo: Array,
        title: { type: String, default: "" }, // 标题
        top: { type: String, default: "" }, // 距离屏幕上边界距离
        right: { type: String, default: "" }, // 距离屏幕右边界距离
        bottom: { type: String, default: "" }, // 距离屏幕下边界距离
        left: { type: String, default: "" }, // 距离屏幕左边界距离
        width: { type: String, default: "" }, // 同CSS
        height: { type: String, default: "" }, // 同CSS
        backgroundColor: { type: String, default: "" }, // 同CSS
        borderRadius: [String, Array], // 同CSS
        position: { type: String, default: "" }, // 同CSS
        display: { type: String, default: "" }, // 同CSS
        padding: [String, Array], // 同CSS [String, Array]
        margin: [String, Array], // 同CSS
        zIndex: { type: String, default: "" }, // 同CSS
        fontSize: { type: String, default: "" }, // 同CSS
        fontWeight: { type: String, default: "" }, // 同CSS
        custom: Boolean,
        isEmpty: Boolean,
        pointEvent: Boolean,
        active: { type: Number, default: 0 } // 当前显示的图表下标
    },
    components: {
        TabBtn
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
        toggleDimension(v) {
            this.$emit("changeTab", v);
        },
    },
    computed: {
        wrapperStyle() {
            return {
                top: this.top,
                bottom: this.bottom,
                left: this.left,
                right: this.right,
                width: this.width,
                height: this.height,
                backgroundColor: this.backgroundColor,
                borderRadius: this.borderRadius && this.borderRadius.join(" "),
                position: this.position,
                display: this.display,
                zIndex: this.zIndex,
                fontSize: this.fontSize,
                fontWeight: this.fontWeight,
                padding: this.padding && this.padding.join(" "),
                margin: this.margin && this.margin.join(" "),
            };
        },
    },
};
</script>
  
<style lang="scss" scoped>
.component-wrapper-container {
    position: relative;
    width: 100%;
    min-height: 100%;
    box-sizing: border-box;
    padding: 8px;
    background-color: rgba(14, 67, 143, 1);
    border-radius: 10px;
    margin: 10px auto;
    display: flex;
    flex-direction: column;

    .block-title {
        line-height: 35px;
        position: relative;
        color: #33E9FF;
        padding-left: 22px;
        font-size: 16px;
        letter-spacing: 2px;
        font-weight: bold;
        height: 35px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("./assets/images/title-bg.png");
        text-align: left;
    }
}
</style>
  