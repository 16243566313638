export function changeRgbaStr(from, to) {
  to.forEach((i, index) => {
    if (i !== undefined) {
      let v = typeof i === "function" ? i(from[index]) : i;
      if (v < 0) {
        v = 0;
      } else if (v > 255) {
        v = 255;
      }
      from[index] = v;
    }
  });
  return `rgba(${from.join(",")})`;
}

export function strColor2RgbaArr(strOrg) {
  let str = strOrg.replace(/\s/g, "");
  let rgbaRegExp =
    /^rgba?\(([.0-9]{1,}),([.0-9]{1,}),([.0-9]{1,}),?([.0-9]{1,})?\)$/i;
  let rgbaExec = rgbaRegExp.exec(str);
  if (rgbaExec) {
    let [, _r, _g, _b, _a = 1] = rgbaExec;
    let rgba = [_r, _g, _b, _a];
    return rgba;
  }
  let hexaRegExp = /^#?([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})([0-9a-f]{2})?$/i;
  let hexaExec = hexaRegExp.exec(str);
  if (hexaExec) {
    let [, _r, _g, _b, _a] = Array.from(hexaExec).map((i) => {
      let v = parseInt(i, 16);
      if (Number.isNaN(v)) {
        return 1;
      } else {
        return v;
      }
    });
    let rgba = [_r, _g, _b, _a];
    return rgba;
  }
  console.warn("传入的不是标准格式颜色:", strOrg);
  return [];
}

export function changeColorStr(strOrg, { r, g, b, a } = {}) {
  return changeRgbaStr(strColor2RgbaArr(strOrg), [r, g, b, a]);
}

export class PickUpColor {
  count = 0;
  colorObjSet = [];
  constructor(colorSet, interval = 3, offset = 0.41) {
    if (colorSet instanceof Array) {
      this.colorObjSet = colorSet.map(strColor2RgbaArr);
      this.colorObjSetRoll = [...this.colorObjSet, this.colorObjSet[0]];
    }
    this.interval = interval > -1 ? interval : 0;
    this.offset = offset;
  }
  f2t(f, t, prc) {
    f = Number(f);
    t = Number(t);
    return f + (t - f) * prc;
  }
  getValue() {
    let colorSetLen = this.colorObjSetRoll.length - 1;
    let colorInterval = this.count % (this.interval + 1);
    let turnCount = parseInt(this.count / (this.interval + 1) / colorSetLen);
    let colorIndex = parseInt(this.count / (this.interval + 1)) % colorSetLen;
    let [colorFrom, colorTo] = this.colorObjSetRoll.slice(
      colorIndex,
      colorIndex + 2
    );
    let percentage =
      (colorInterval + ((this.offset * turnCount) % 1)) / (this.interval + 1);
    let rgba = colorFrom.map((v, index) => {
      let vNumber = this.f2t(colorFrom[index], colorTo[index], percentage);
      return (vNumber.toFixed && vNumber.toFixed(2)) || vNumber;
    });
    this.count++;
    return `rgba(${rgba.join(",")})`;
  }
}

export const easingFuncs = {
  linear: function (k) {
    return k;
  },
  quadraticIn: function (k) {
    return k * k;
  },
  quadraticOut: function (k) {
    return k * (2 - k);
  },
  quadraticInOut: function (k) {
    if ((k *= 2) < 1) {
      return 0.5 * k * k;
    }
    return -0.5 * (--k * (k - 2) - 1);
  },
  cubicIn: function (k) {
    return k * k * k;
  },
  cubicOut: function (k) {
    return --k * k * k + 1;
  },
  cubicInOut: function (k) {
    if ((k *= 2) < 1) {
      return 0.5 * k * k * k;
    }
    return 0.5 * ((k -= 2) * k * k + 2);
  },
  quarticIn: function (k) {
    return k * k * k * k;
  },
  quarticOut: function (k) {
    return 1 - --k * k * k * k;
  },
  quarticInOut: function (k) {
    if ((k *= 2) < 1) {
      return 0.5 * k * k * k * k;
    }
    return -0.5 * ((k -= 2) * k * k * k - 2);
  },
  quinticIn: function (k) {
    return k * k * k * k * k;
  },
  quinticOut: function (k) {
    return --k * k * k * k * k + 1;
  },
  quinticInOut: function (k) {
    if ((k *= 2) < 1) {
      return 0.5 * k * k * k * k * k;
    }
    return 0.5 * ((k -= 2) * k * k * k * k + 2);
  },
  sinusoidalIn: function (k) {
    return 1 - Math.cos((k * Math.PI) / 2);
  },
  sinusoidalOut: function (k) {
    return Math.sin((k * Math.PI) / 2);
  },
  sinusoidalInOut: function (k) {
    return 0.5 * (1 - Math.cos(Math.PI * k));
  },
  exponentialIn: function (k) {
    return k === 0 ? 0 : Math.pow(1024, k - 1);
  },
  exponentialOut: function (k) {
    return k === 1 ? 1 : 1 - Math.pow(2, -10 * k);
  },
  exponentialInOut: function (k) {
    if (k === 0) {
      return 0;
    }
    if (k === 1) {
      return 1;
    }
    if ((k *= 2) < 1) {
      return 0.5 * Math.pow(1024, k - 1);
    }
    return 0.5 * (-Math.pow(2, -10 * (k - 1)) + 2);
  },
  circularIn: function (k) {
    return 1 - Math.sqrt(1 - k * k);
  },
  circularOut: function (k) {
    return Math.sqrt(1 - --k * k);
  },
  circularInOut: function (k) {
    if ((k *= 2) < 1) {
      return -0.5 * (Math.sqrt(1 - k * k) - 1);
    }
    return 0.5 * (Math.sqrt(1 - (k -= 2) * k) + 1);
  },
  elasticIn: function (k) {
    var s;
    var a = 0.1;
    var p = 0.4;
    if (k === 0) {
      return 0;
    }
    if (k === 1) {
      return 1;
    }
    if (!a || a < 1) {
      a = 1;
      s = p / 4;
    } else {
      s = (p * Math.asin(1 / a)) / (2 * Math.PI);
    }
    return -(
      a *
      Math.pow(2, 10 * (k -= 1)) *
      Math.sin(((k - s) * (2 * Math.PI)) / p)
    );
  },
  elasticOut: function (k) {
    var s;
    var a = 0.1;
    var p = 0.4;
    if (k === 0) {
      return 0;
    }
    if (k === 1) {
      return 1;
    }
    if (!a || a < 1) {
      a = 1;
      s = p / 4;
    } else {
      s = (p * Math.asin(1 / a)) / (2 * Math.PI);
    }
    return (
      a * Math.pow(2, -10 * k) * Math.sin(((k - s) * (2 * Math.PI)) / p) + 1
    );
  },
  elasticInOut: function (k) {
    var s;
    var a = 0.1;
    var p = 0.4;
    if (k === 0) {
      return 0;
    }
    if (k === 1) {
      return 1;
    }
    if (!a || a < 1) {
      a = 1;
      s = p / 4;
    } else {
      s = (p * Math.asin(1 / a)) / (2 * Math.PI);
    }
    if ((k *= 2) < 1) {
      return (
        -0.5 *
        (a *
          Math.pow(2, 10 * (k -= 1)) *
          Math.sin(((k - s) * (2 * Math.PI)) / p))
      );
    }
    return (
      a *
        Math.pow(2, -10 * (k -= 1)) *
        Math.sin(((k - s) * (2 * Math.PI)) / p) *
        0.5 +
      1
    );
  },
  // 在某一动画开始沿指示的路径进行动画处理前稍稍收回该动画的移动
  backIn: function (k) {
    var s = 1.70158;
    return k * k * ((s + 1) * k - s);
  },
  backOut: function (k) {
    var s = 1.70158;
    return --k * k * ((s + 1) * k + s) + 1;
  },
  backInOut: function (k) {
    var s = 1.70158 * 1.525;
    if ((k *= 2) < 1) {
      return 0.5 * (k * k * ((s + 1) * k - s));
    }
    return 0.5 * ((k -= 2) * k * ((s + 1) * k + s) + 2);
  },
  // 创建弹跳效果
  bounceIn: function (k) {
    return 1 - easingFuncs.bounceOut(1 - k);
  },
  bounceOut: function (k) {
    if (k < 1 / 2.75) {
      return 7.5625 * k * k;
    } else if (k < 2 / 2.75) {
      return 7.5625 * (k -= 1.5 / 2.75) * k + 0.75;
    } else if (k < 2.5 / 2.75) {
      return 7.5625 * (k -= 2.25 / 2.75) * k + 0.9375;
    } else {
      return 7.5625 * (k -= 2.625 / 2.75) * k + 0.984375;
    }
  },
  bounceInOut: function (k) {
    if (k < 0.5) {
      return easingFuncs.bounceIn(k * 2) * 0.5;
    }
    return easingFuncs.bounceOut(k * 2 - 1) * 0.5 + 0.5;
  },
};

export function ab2str(buf) {
  return Utf8ArrayToStr(new Uint8Array(buf));
  // return String.fromCharCode.apply(null, new Uint8Array(buf));
}

function Utf8ArrayToStr(array) {
  var out, i, len, c;
  var char2, char3;
  out = "";
  len = array.length;
  i = 0;
  while (i < len) {
    c = array[i++];
    switch (c >> 4) {
      case 0:
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
        // 0xxxxxxx
        out += String.fromCharCode(c);
        break;
      case 12:
      case 13:
        // 110x xxxx 10xx xxxx
        char2 = array[i++];
        out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
        break;
      case 14:
        // 1110 xxxx 10xx xxxx 10xx xxxx
        char2 = array[i++];
        char3 = array[i++];
        out += String.fromCharCode(
          ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
        );
        break;
    }
  }
  return out;
}

/**
 * @param {string} rootId 根节点id
 * @param {Object[]} flatList 一维数组
 * @param {Object[]} list 树状数组
 * @returns {Object}
 */

export function arrayFlat2Tree({
  key = "id",
  parentKey = "parentId",
  rootId,
  flatList,
  list = [],
}) {
  if (!(flatList && flatList.length)) {
    return [];
  }
  if (!list.length) {
    //处理根节点，如果传了就用传入的
    let rId = rootId;
    if (!rId) {
      const root = flatList.find((i) => i.root);
      if (root) {
        rId = root[parentKey];
      }
    }
    if (!rId) {
      console.warn("arrayFlat2Tree，无根节点");
      return [];
    }
    // for (let i = flatList.length - 1; i > -1; i--) {
    //   if (flatList[i][parentKey] === rId) {
    //     list.push(flatList[i]);
    //     flatList.splice(i, 1);
    //   }
    // }
    for (let i = 0; i < flatList.length; i++) {
      if (flatList[i][parentKey] === rId) {
        list.push(flatList[i]);
        flatList.splice(i, 1);
        i--;
      }
    }
    arrayFlat2Tree({
      key,
      parentKey,
      flatList,
      list,
    });
  } else {
    for (let i = 0; i < list.length; i++) {
      let children = [];
      for (let j = flatList.length - 1; j > -1; j--) {
        if (flatList[j][parentKey] === list[i][key]) {
          children.unshift(flatList[j]);
          flatList.splice(j, 1);
        }
      }
      if (children.length > 0) {
        list[i].children = children;
        arrayFlat2Tree({
          key,
          parentKey,
          flatList,
          list: children,
        });
      }
    }
  }
  return list;
}

export function getBoundsByGeoJson(geoJson) {
  let x0 = 0,
    x1 = 360,
    y0 = -90,
    y1 = 90;
  let features;
  if (
    geoJson.type === "FeatureCollection" &&
    geoJson.features instanceof Array
  ) {
    features = geoJson.features;
  } else if (geoJson instanceof Array) {
    features = geoJson;
  }
  features.forEach((f) => {
    let geometry = f.geometry;
    if (geometry && ["Polygon", "MultiPolygon"].includes(geometry.type)) {
      let pts = [];
      if (geometry.type === "MultiPolygon") {
        geometry.coordinates.forEach((c) => {
          c.forEach((pt) => {
            pts.push(...pt);
          });
        });
      } else if (geometry.type === "Polygon") {
        geometry.coordinates.forEach((pt) => {
          pts.push(...pt);
        });
      }
      pts.forEach(([x, y]) => {
        if (x > x0) {
          x0 = x;
        }
        if (x < x1) {
          x1 = x;
        }
        if (y > y0) {
          y0 = y;
        }
        if (y < y1) {
          y1 = y;
        }
      });
    }
  });
  if (x0 === 0 && x1 === 360 && y0 === -90 && y1 === 90) {
    // 如果没有获取到边界信息 返回 中国边界
    return [
      [59, 15],
      [146, 55],
    ];
  } else {
    return [
      [x0, y0],
      [x1, y1],
    ];
  }
}

export function getBoundsByCoordinates(coordinates) {
  let x0 = 0,
    x1 = 360,
    y0 = -90,
    y1 = 90;
  coordinates.forEach(([x, y]) => {
    if (typeof x === "number" && typeof y === "number") {
      if (x > x0) {
        x0 = x;
      }
      if (x < x1) {
        x1 = x;
      }
      if (y > y0) {
        y0 = y;
      }
      if (y < y1) {
        y1 = y;
      }
    }
  });
  if (x0 === 0 && x1 === 360 && y0 === -90 && y1 === 90) {
    // 如果没有获取到边界信息 返回 中国边界
    return [
      [59, 15],
      [146, 55],
    ];
  } else {
    return [
      [x0, y0],
      [x1, y1],
    ];
  }
}

export function getTextWidth(text, fontSize) {
  // 创建临时元素
  const _span = document.createElement("span");
  // 放入文本
  _span.innerText = text;
  // 设置文字大小
  _span.style.fontSize = fontSize + "px";
  // span元素转块级
  _span.style.position = "absolute";
  // span放入body中
  document.body.appendChild(_span);
  // 获取span的宽度
  let width = _span.offsetWidth;
  // 从body中删除该span
  document.body.removeChild(_span);
  // 返回span宽度
  return width;
}

// 根据子id找父id,返回值不包含查找的id
// export function findParentsId(treeData, id) {
//   if (treeData.length == 0) return;
//   for (let i = 0; i < treeData.length; i++) {
//     if (treeData[i].id == id) {
//       return [];
//     } else {
//       if (treeData[i].children) {
//         let res = findParentsId(treeData[i].children, id);
//         if (res !== undefined) {
//           return res.concat(treeData[i].id).reverse();
//         }
//       }
//     }
//   }
// }
// 根据子id找父id
export function findParentsId(tree, id, res) {
  let arr = [];
  for (let i = 0; i < tree.length; i++) {
    let item = tree[i];
    arr = [];
    arr.push(item[res]);
    if (id == item[res]) {
      return arr;
    } else {
      if (item.children && item.children.length > 0) {
        arr = arr.concat(findParentsId(item.children, id, res));
        if (arr.includes(id)) {
          return arr;
        }
      }
    }
  }
}
