<template>
    <div class="d-container">
        <div class="item-wrapper" v-for="(item, index) of dataSource" :key="index + '_DataPanel'">
            <div class="item-content">
                <div class="value">{{ item.values[0].y }}</div>
                <div class="name">{{ item.s }}</div>
                <div class="time">{{ item.values[1].x }}
                    <span v-if="item.values[1].y !== ''">{{ item.values[1].y }}</span>
                    <span v-else>-</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "DataPanel",
    props: {
        source: {
            type: Array,
            default: () => [{}]
        }
    },
    data() {
        return {
            dataSource: [],
        };
    },
    watch: {
        source: {
            handler(val) {
                this.getFilterData(val);
            },
            deep: true,
            immeidate: true
        },
    },
    methods: {
        getFilterData(val) {
            this.dataSource = Object.values(val.reduce((res, item) => {
                const { s, x, y } = item;
                const { values = [] } = res[s] || {};
                values.push({ x, y });
                res[s] = { s, values };
                return res;
            }, {}));
        }
    },
    mounted() {
        this.getFilterData(this.source)
    },
}
</script>

<style lang="scss" scoped>
.d-container {
    border-radius: 8px;
    display: flex;
    justify-content: space-around;
    align-items: center;


    .item-wrapper {
        flex: 1;
        text-align: center;
        position: relative;

        .item-content {
            display: inline-block;
            vertical-align: middle;
            background: transparent;

            .value {
                font-size: 28px;
                color: #FFFFFF;
                font-family: DINCond-Bold;
            }

            .name {
                margin-top: 10px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #CAF3FF;
            }

            .time {
                margin-top: 10px;
                font-size: 10px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #C3D9F8;

                span {
                    margin-left: 4px;
                }
            }
        }
    }

    .item-wrapper+.item-wrapper {
        &::after {
            content: '';
            height: 50%;
            width: 1px;
            background-color: rgba(21, 82, 155, 1);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>