<template>
    <component class="uniapp-form-item" :is="componentName" @click="onClick" v-bind="options">
    </component>
</template>
  
<script>
import JsText from "./JsText.vue"

export default {
    name: "JsWord",
    components: {
        JsText
    },
    props: {
        options: {
            type: Object,
            default: () => ({
                source: [],
            }),
        },
        componentName: {
            type: String,
            require: true,
        },
    },
    data() {
        return {};
    },
    watch: {
        options: {
            handler(val) {
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
    },
    methods: {
        //事件传递
        onClick(params) {
            this.$emit("click", params);
        },
    },
};
</script>
  
<style lang="scss" scoped>
.uniapp-form-item {
    position: relative;
    width: 100%;
    height: 100%;
}
</style>
  