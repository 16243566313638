<template>
  <div class="e-chart-wrap" ref="chart"></div>
</template>
 
<script>
import * as Echarts from "echarts";
export default {
  name: "ECharts",
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      chartInstance: null
    };
  },
  watch: {
    options: {
      handler() {
        if (this.chartInstance) {
          this.chartInstance.setOption(this.options, true);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.initChart();
    window.addEventListener("resize", this.changeSize);
  },
  methods: {
    // 图表初始化
    initChart() {
      if (!this.$refs.chart || !this.$refs.chart.clientHeight) {
        return setTimeout(() => {
          this.initChart();
        }, 300);
      }
      // console.dir('clientHeight', this.$refs.chart.clientHeight)
      this.chartInstance = Echarts.init(this.$refs.chart);
      this.chartInstance.setOption(this.options);
      this.chartInstance.on("click", (res) => { // 图标点击事件
        this.$emit('OnEcharts', res)
      });
      this.chartInstance.on("updateAxisPointer", (res) => { // 图标点击事件
        this.$emit('updateAxisPointer', { instance: this.chartInstance, event: res })
      });
    },
    update(options = this.options) {
      this.chartInstance.setOption(options);
    },
    // 自适应宽高
    changeSize() {
      this.$nextTick(() => {
        this.chartInstance && this.chartInstance.resize();
      });
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.changeSize);
    this.chartInstance && this.chartInstance.dispose();
  },
};
</script>
 
<style lang='scss' scoped>
.e-chart-wrap {
  position: relative;
  width: 100%;
  height: 100%;
}
</style>