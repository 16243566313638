import Vue from "vue";
import App from "./App.vue";
import store from "@/store";
import router from "@/router";
import _ from "lodash";
import { Tab, Tabs } from "vant";

Vue.config.productionTip = false;
Vue.prototype._ = _;

Vue.use(Tab);
Vue.use(Tabs);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
