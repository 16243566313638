<template>
    <highcharts :options="chartOptions" :highcharts="hcInstance" ref="chart" v-if="showChart"></highcharts>
</template>
   
  <script>
import highcharts from 'highcharts'
import highcharts3d from 'highcharts/highcharts-3d'
import SolidGauge from 'highcharts/modules/solid-gauge.js'
import HighchartsMore from 'highcharts/highcharts-more';
import wordcloud from "highcharts/modules/wordcloud";
import heightCylinder from '@/plugins/highcharts/height.cylinder.js'
import { Chart } from 'highcharts-vue'

highcharts.setOptions({
    credits: {
        enabled: false
    }
})

highcharts3d(highcharts)
HighchartsMore(highcharts)
SolidGauge(highcharts);
heightCylinder(highcharts);
wordcloud(highcharts);

export default {
    name: "HighCharts",
    components: {
        highcharts: Chart
    },
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            chartInstance: null,
            hcInstance: highcharts,
            chartOptions: {},
            showChart: true
        };
    },
    watch: {
        options: {
            handler(newVal) {
                if (newVal) {
                    this.update(newVal);
                }
            },
            deep: true,
            immediate: true
        },
    },
    mounted() {
        this.initChart();
        window.addEventListener("resize", this.changeSize);
    },
    methods: {
        // 图表初始化
        initChart() {
            if (this.$refs.chart) {
                this.chartInstance = this.$refs.chart.chart;
            }
        },
        // 图表更新
        update(options = this.options) {
            this.chartOptions = options;
            this.showChart = false;
            this.$nextTick(() => {
                this.showChart = true
            })
        },
        // 自适应宽高
        changeSize() {
            if (this.chartInstance) {
                this.chartInstance.reflow();
            }
        },
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.changeSize);
        // this.chartInstance && this.chartInstance.destroy();
    },
};
</script>
   
  <style lang='scss' scoped>
  .e-chart-wrap {
      position: relative;
      width: 100%;
      height: 100%;
  }
  </style>