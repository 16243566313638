<template>
    <div class="js-text-wrapper" @click="onClick">
        <text class="js-text" :style='styleCpt'>{{ textContent }}</text>
    </div>
</template>
  
<script>

export default {
    name: "JsText",
    props: {
        source: [String, Array],
        colors: {
            type: Array,
            default: () => (["ffffff"])
        }
    },
    data() {
        return {
            textContent: ""
        };
    },
    watch: {
        source: {
            handler(val) {
                this.transformData(val);
            },
            deep: true,
            immeidate: true
        },
    },
    computed: {
        styleCpt() {
            return { color: this.colors[0] }
        }
    },
    mounted() {
    },
    methods: {
        onClick() {
            let param = "";
            if (this.source && this.source.length) {
                param = this.source[0].y
            }
            this.$emit("click", param)
        },
        transformData(source) {
            if (source && source.length) {
                this.textContent = source[0].x;
            }
        },
    },
};
</script>
  
<style lang="scss" scoped>
.js-text-wrapper {
    width: 100%;
    height: 100%;

    .js-text {
        width: 100%;
        height: 100%;
    }
}
</style>
  